<template>
  <b-modal id="modalShowNewDev" hide-footer centered @show="addClass" @hidden="removeIframe" content-class="modal--news--dev">
    <template #modal-header>
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="11">
            <h1 class="modal--news--dev-title">
              ¡Nos renovamos! 
              <b-badge variant="success" class="modal--new-dev-badge">New</b-badge>
            </h1>
          </b-col>
          <b-col cols="1" class="p-0 text-center">
            <b-button @click="$bvModal.hide('modalShowNewDev')" variant="link" class="p-0">
              <feather-icon
                icon="XIcon"
                size="25"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <news-carousel :role="role" @emptyRows="removeIframe"></news-carousel>
    <b-button variant="link" class="p-0 w-100 text-right" @click="setCookie('quaterNotifSeen', true, 120)">No volver a mostrar</b-button>
  </b-modal>
</template>
<script>
import _ from 'lodash'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import NewsCarousel from './components/newsCarousel.vue'
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    NewsCarousel
  },
  data() {
    return {
      attempt: 0,
      role: this.$route.query.role
    }
  },
  mounted() {
    if (!this.getCookie('quaterNotifSeen')) {
      this.$bvModal.show('modalShowNewDev')
    } else {
      this.removeIframe()
    }
  },
  methods: {
    findIframeByName(name) {
      return _.find(window.frames, frame => frame.name === name)
    },
    addClass() {
      if (document.querySelector('.modal-open')) {
        this.attempt = 0
        document.querySelector('.modal-open').classList.add('transparent--class')
      } else if (this.attempt < 5) {
        setTimeout(() => {
          this.attempt += 1
          this.addClass()
        }, 500)
      }
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      const expires = `expires=${d.toUTCString()}`
      document.cookie = `${cname}=${cvalue};${expires};path=/`
      this.addCookie()
      this.$bvModal.hide('modalShowNewDev')
    },
    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie ?? '')
      if (decodedCookie !== '') {
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i]
          while (c.charAt(0) === ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
          }
        }
      }
      return ''
    },
    removeIframe() {
      window.parent.postMessage('close-modal', '*')
    },
    addCookie() {
      window.parent.postMessage('close-modal-cookie', '*')
    }
  }
}
</script>
<style lang="scss">
  .transparent--class {
    background: transparent
  }

  .modal--news--dev {
    background: linear-gradient(0deg, rgba(27, 66, 132, 0.02), rgba(27, 66, 132, 0.02)), #FFFBFE;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    gap: 1rem;

    &, .modal-header {
      border-radius: 20px;
    }

    .modal-header {
      background: transparent;
      padding: 0;
    }

    .modal-body {
      padding: 0;
    }

    &-badge {
      font-size: 0.7rem;
      margin-left: 1rem
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin: 0;

      color: #1B4284;
    }
  }


</style>