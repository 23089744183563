<template>
  <b-carousel
    class="carousel--news"
    id="carouselNews"
    style="text-shadow: 0px 0px 2px #000" fade
    indicators>
    <b-carousel-slide v-for="(card, index) of filterObjectCard" :key="index">
      <div class="carousel--slide-text">
        <p class="text-left" v-html="card.text1"></p>
        <p class="text-left" v-html="card.text2"></p>
      </div>
      <img class="carousel--news-image" :src="card.img" alt="" srcset="">
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
export default {
  props: ['role'],
  data() {
    return {
      objectCard: [
        {
          text1: 'Ahora podrás activar el protocolo al momento de gestionar tus envíos con incidencia. Encuéntralo en la nueva sección <b>Protocolo</b>, dentro de la categoría <b>Tickets</b>.',
          text2: '',
          img: 'https://storage.googleapis.com/tickets-misc/supportFiles/Ilustracion%201%20config.svg',
          profile: ['seller', 'ecommerce', 'employee']
        },
        {
          text1: 'Ahora tus clientes podrán reportar directamente las incidencias de sus envíos',
          text2: 'Activa esta nueva funcionalidad desde <b>Mí Empresa > Notificaciones > Mail Entrega Cliente</b>.',
          img: 'https://storage.googleapis.com/tickets-misc/supportFiles/Ilustracion%202%20notificacion.svg',
          profile: ['seller', 'ecommerce', 'employee']
        },
        {
          text1: 'Ahora podrás activar el protocolo al momento de gestionar tus envíos con incidencia. Encuéntralo en la nueva sección <b>Protocolo</b>, dentro de la categoría <b>Empresas</b>.',
          text2: '',
          img: 'https://storage.googleapis.com/tickets-misc/supportFiles/Ilustracion%201%20config.svg',
          profile: ['marketplace', 'mkp', 'admin', 'superadmin']
        },
        {
          text1: 'Ahora tus clientes podrán reportar directamente las incidencias de sus envíos.',
          text2: 'Activa esta nueva funcionalidad desde <b>Empresa > Notificaciones > Mail Entrega Cliente</b>.',
          img: 'https://storage.googleapis.com/tickets-misc/supportFiles/Ilustracion%202%20notificacion.svg',
          profile: ['marketplace', 'mkp', 'admin', 'superadmin']
        }
      ],
      filterObjectCard: []
    }
  },
  components: {
    BCarousel,
    BCarouselSlide
  },
  mounted() {
    const role = this.$session.get('cas_user')?.role ?? this.role
    this.filterObjectCard = this.objectCard.filter((element) => element.profile.includes(role))
    if (this.filterObjectCard.length === 0) this.$emit('emptyRows', true)
  },
  methods: {
    
  }
}
</script>
<style lang="scss" >
  .carousel--news {
    .carousel-indicators li {
      background-color: #1B4284;
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
    .carousel-caption {
      color: black;
      top: 0;
      overflow: scroll;
      margin-bottom: 0;
      text-shadow: none;
      left: 0;
      right: 0;
      padding: 0;
      overflow: hidden;
    }
    .carousel-indicators {
      margin-bottom: 0;
    }
    .carousel-item.active {
      height: 380px;
    }

    &-image {
      width: 240px;
      height: 240px;
    }
    .carousel--slide-text {
      min-height: 98px;
    }
  }
</style>
